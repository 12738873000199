.contextbtn {
  width: 130px;
  height: 130px;
  background-image: url("../../Assets/pluxeenew/pinx.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}
