/* background:"#221c46",color:"white",border:"0.5px solid white",position:"fixed",bottom:"70px",right:"0" */

.floating_box .floating_btn {
  background: rgb(0, 96, 170);
  color: white;
  border: 0.5px solid white;
  position: fixed;
  bottom: 72px;
  right: 35%;
}
.floating_box .floating_btn:hover {
  background: #221c46;
  color: white;
  border: 0.5px solid white;
}

@media only screen and (max-width: 2560px) {
  .floating_box .floating_btn {
    right: 42.5%;
  }
}
@media only screen and (max-width: 2000px) {
  .floating_box .floating_btn {
    right: 40%;
  }
}
@media only screen and (max-width: 1600px) {
  .floating_box .floating_btn {
    right: 38%;
  }
}
@media only screen and (max-width: 1440px) {
  .floating_box .floating_btn {
    right: 37%;
  }
}
@media only screen and (max-width: 1366px) {
  .floating_box .floating_btn {
    right: 35%;
  }
}
@media only screen and (max-width: 1200px) {
  .floating_box .floating_btn {
    right: 33%;
  }
}
@media only screen and (max-width: 1024px) {
  .floating_box .floating_btn {
    right: 30%;
  }
}
@media only screen and (max-width: 900px) {
  .floating_box .floating_btn {
    right: 27%;
  }
}
@media only screen and (max-width: 800px) {
  .floating_box .floating_btn {
    right: 25%;
  }
}
@media only screen and (max-width: 768px) {
  .floating_box .floating_btn {
    right: 23%;
  }
}
@media only screen and (max-width: 650px) {
  .floating_box .floating_btn {
    right: 19%;
  }
}
@media only screen and (max-width: 550px) {
  .floating_box .floating_btn {
    right: 13%;
  }
}
@media only screen and (max-width: 500px) {
  .floating_box .floating_btn {
    right: 9%;
  }
}
@media only screen and (max-width: 425px) {
  .floating_box .floating_btn {
    right: 3%;
  }
}
