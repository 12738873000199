.login_data {
  width: 100%;
  margin: 10px auto 0px auto;
  padding: 10px 10px;
}

@media only screen and (max-width: 425px) {
  .login_data {
    padding: 15px 10px;
  }
}
