/* minWidth:"400px",height:"80vh",overflowY:"auto",padding:"5px 15px 140px 15px",overflow:"auto" */

textarea::placeholder{
    font-size: 20px;
    font-weight: 400;
}
.card_container{
    min-width: 400px;
    height: 80vh;
    overflow-y: auto;
    padding: 5px 15px 140px 15px;
}
.card_container::-webkit-scrollbar{
   display: none;
}
.card_container .comment_card{
   width: 100%;
   box-shadow: 0 0 2px 0.5px gray;
   background-color: whitesmoke;
   margin-bottom: 15px;
   border-radius: 10px;
   padding: 10px;
}
.card_container .comment_card .comment_box_header{
   width: 100%;
   display: flex;
   justify-content: flex-start;
   align-items: center;
}
.card_container .comment_card .comment_box_header .comment_box_avatar{
   margin-right: 8px;
}
.card_container .comment_card .comment_box_header .comment_box_name_date{
   display: flex;
   justify-content: flex-start;
   align-items: center;
}

.card_textarea_container{
    width: 100%;
    position: absolute;
    bottom: 0;
    padding: 10px;
}
/* style={{minWidth:"400px",position:"absolute",bottom:"0",padding:"5px 30px 12px 15px"}} */
.card_textarea_container .card_textarea{
    width: 100%;
    background-color: whitesmoke;
    border-radius: 10px;
    padding: 10px 0 5px 0;
}
.card_textarea_container .card_textarea .card_textarea_input{
    padding: 0 10px;
}

/* style={{padding:"5px 10px",display:"flex",flexDirection:"column",justifyContent:"flex-start",alignItems:"flex-start"}} */
/* style={{padding:"0px 10px", display:"flex",justifyContent:"space-between",alignItems:"center"}} */

.card_textarea_container .card_textarea .card_textarea_button{
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.delete_popup_box{
    width: 100%;
    padding: 10px 5px;
}
.delete_popup_box .delete_popup_static{
    width: 100%;
    padding: 0;
}
.delete_popup_box .delete_popup_static .delete_popup_title{
    color: #334253;
    font-family: var(--fontfamily);
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 10px;
}
.delete_popup_box .delete_popup_static .delete_popup_subtitle{
    color: #334253;
    font-family: var(--fontfamily);
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
}
.delete_popup_box .delete_popup_btns{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0;
    margin-top: 20px;
}
.delete_popup_box .delete_popup_btns .delete_popup_btn1{
    background: #67727E;
    padding: 6px 12px;
    color: #ffffff;
    font-family: var(--fontfamily);
    font-weight: 500;
    font-size: 16px;
    margin: 5px 10px;
}
.delete_popup_box .delete_popup_btns .delete_popup_btn2{
    background: #ED6368;
    padding: 6px 12px;
    color: #ffffff;
    font-family: var(--fontfamily);
    font-weight: 500;
    font-size: 16px;
    margin: 5px 10px;
}


@media only screen and (max-width: 470px){
    .card_container{
        min-width: 100%;
        height: 80vh;
        overflow-y: auto;
        padding: 5px 15px 140px 15px;
    }
    .card_textarea_container .card_textarea{
        width: 97%;
    }
    
}
@media only screen and (max-width: 422px){
    .card_container .comment_card .comment_box_header .comment_box_name_date{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
     }

}
@media only screen and (max-width: 347px){
    .delete_popup_box .delete_popup_btns .delete_popup_btn1{
        font-size: 14px;
    }
    .delete_popup_box .delete_popup_btns .delete_popup_btn2{
        font-size: 14px;
    }

}
@media only screen and (max-width: 320px){
    .delete_popup_box .delete_popup_btns .delete_popup_btn1{
        font-size: 11px;
    }
    .delete_popup_box .delete_popup_btns .delete_popup_btn2{
        font-size: 11px;
    }

}