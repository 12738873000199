.notifi_card {
  width: 500px;
  margin: 10px 10px;
  background: rgb(0, 96, 170);
  color: #ffffff;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 1.5px 1.5px 1.5px 1px rgb(7, 58, 97);
}

@media only screen and (max-width: 528px) {
  .notifi_card {
    width: 100%;
  }
}
