.leaderboard_container{
    width: 100%;
    display: grid;
    place-items: center;
}
.leaderboard_btns{
    width: 500px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: #908DA2;
}
.leaderboard_btns .leaderboard_select_tag{
    width: 26%;
    display: inline-block;
    background: #D9D9D9;
    border: 5px solid #D9D9D9;
    outline: none;
    color: #221C46;
    padding: 10px 5px;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Roboto Condensed', sans-serif;
}
/* .select_option_style{
    width: 40px;
    height: 100px;
    border: 1px solid red;
    position: absolute;
    right: 0;
    top: 0;
} */
.leaderboard_data{
    width: 500px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: #17CCF9;
}
.odd_data{
    width: 500px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: #ffffff;
}
.odd_data .col1{
    width: 25%;
    font-size: 18px;
    font-weight: 500;
    font-family: 'Roboto Condensed', sans-serif;
    color: #221C46;
    padding: 0 0 0 10px;
}
.odd_data .col2{
    width: 50%;
    text-align: start;
    font-size: 18px;
    font-weight: 500;
    font-family: 'Roboto Condensed', sans-serif;
    color: #221C46;
    padding-right: 10px;
}
.odd_data .col3{
    width: 25%;
    font-size: 18px;
    font-weight: 500;
    font-family: 'Roboto Condensed', sans-serif;
    color: #221C46;
    padding: 10px 8px;
}

.even_data{
    width: 500px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: #221C46;
}
.even_data .col1{
    width: 25%;
    font-size: 18px;
    font-weight: 500;
    font-family: 'Roboto Condensed', sans-serif;
    color: #ffffff;
    padding: 0 0 0 10px;
}
.even_data .col2{
    width: 50%;
    text-align: start;
    font-size: 18px;
    font-weight: 500;
    font-family: 'Roboto Condensed', sans-serif;
    color: #ffffff;
    padding-right: 10px;
}
.even_data .col3{
    width: 25%;
    font-size: 18px;
    font-weight: 500;
    font-family: 'Roboto Condensed', sans-serif;
    color: #ffffff;
    padding: 10px 8px;
}



/* width:"25%",background:"#D9D9D9",border:"1px solid #D9D9D9",outline:"none",fontSize:"18px",fontWeight:"500",fontFamily:"'Roboto Condensed', sans-serif",color:"#221C46",padding:"10px 10px" */
@media only screen and (max-width: 502px) {
    .leaderboard_btns{
        width: 100%;
    }
    .leaderboard_data{
width: 100%;
    }
.odd_data{
width: 100%;
}
.even_data{
    width: 100%;
}
}