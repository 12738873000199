.chatroom_container {
  width: 100%;
  height: 80vh;
  display: grid;
  place-items: center;
  overflow: hidden;
}
.chatroom_innercontainer {
  width: 500px;
  height: 68vh;
  display: flex;
  flex-direction: column !important;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10vh;
  /* overflow-y: auto; */
}
/* width:"25%",background:"#D9D9D9",border:"1px solid #D9D9D9",outline:"none",fontSize:"18px",fontWeight:"500",fontFamily:"'Roboto Condensed', sans-serif",color:"#221C46",padding:"10px 10px" */
@media only screen and (max-width: 502px) {
  .chatroom_innercontainer {
    width: 100%;
  }
}
