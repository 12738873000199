.rout_container {
  width: 100%;
  padding: 30px 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 500px;
  margin: 20px auto 0 auto;
  border-radius: 50px 50px 0px 0px;
  background-color: rgb(80, 112, 143);
}
.routnavbar {
  width: 120px;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5px;
  text-decoration: none;
  color: #000000;
  text-align: center;
  background: #ffffff;
  padding: 10px;
  border-radius: 10px;
}

.pluxeelogo {
  font-size: 35px;
  font-weight: 800;
  letter-spacing: 0.5px;
  display: flex;
  align-items: center;
}

.pluxeeletter {
  position: relative;
  display: inline-block;
  -webkit-text-stroke: 2px rgba(34, 28, 70, 1);
  line-height: 1;
  color: #ffffff;
}

.pluxeeletter:last-child {
  margin-right: 0;
}

.pluxeetext {
  color: #ffffff;
}

.puxeehighlight {
  color: rgba(0, 235, 94, 1);
  -webkit-text-stroke: 2px rgba(34, 28, 70, 1);
}

@media only screen and (max-width: 425px) {
  .rout_container {
    padding: 30px 15px;
  }
  .rout_container .routnavbar {
    width: 100px;
  }
}
