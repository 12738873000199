@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --bg: rgba(255, 255, 255, 1);
  --border: #0069b4;
  --loginBg: #908da2;
  --text: #000000;
  --fontfamily: "Roboto Condensed", sans-serif;
}

body {
  width: 100%;
  height: 100%;
  font-family: var(--fontfamily);
  background: var(--bg);
}
