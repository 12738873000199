.options {
  width: 100%;
}
.singleOption {
  width: 100%;
  border-left: 4px solid rgba(95, 188, 84, 1);
  border-radius: 8px;
  background: rgba(31, 29, 79, 1);
  font-size: 16px;
  color: white;
  font-weight: 500;
  padding: 10px 0 10px 10px;
  margin: 5px 0;
  text-align: left;
  font-family: "Roboto Condensed", sans-serif;
  cursor: pointer;
}

.select {
  background: #00eb5e;
  border-left: 0;
  color: #221c46;
  font-family: "Roboto Condensed", sans-serif;
}

.wrong {
  background: #ff7375;
  border-left: 0;
  color: #221c46;
  font-family: "Roboto Condensed", sans-serif;
}
