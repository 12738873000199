.homevideo {
  height: 200px;
}

@media only screen and (max-width: 768px) {
  .homevideo {
    width: 70%;
  }
}
@media only screen and (max-width: 425px) {
  .homevideo {
    width: 90%;
  }
}
