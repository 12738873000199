.footer_rout_container {
  width: 100%;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(80, 112, 143);
}

.footnavbar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: var(--fontfamily);
  margin: 0 20px;
  text-decoration: none;
  color: #ffffff;
}

@media only screen and (max-width: 500px) {
  .footer_rout_container {
    width: 100%;
  }
  .footnavbar {
    margin: 0 15px;
  }
}

@media only screen and (max-width: 436px) {
  .footer_rout_container {
    width: 100%;
    padding: 10px 5px;
  }
  .footnavbar {
    margin: 0 10px;
  }
}

@media only screen and (max-width: 360px) {
  .footer_topogpy {
    font-size: 8px !important;
  }
}
